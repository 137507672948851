import React from "react"
import { Stack, styled } from "@mui/material"

import { ControlButtons, DemoSelect, ProgressBar, SpeedSelect } from "./fragments"

export const DemoConteroller: React.FC = () => {
  return (
    <SContainer>
      <DemoSelect />
      <SpeedSelect />
      <ControlButtons />
      <ProgressBar />
    </SContainer>
  )
}

const SContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: "12px",
  padding: "6px",
  background: theme.palette.secondary.A700,
}))
