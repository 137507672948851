import React, { useEffect, useMemo, useState } from "react"
import { IconButton, Stack, styled } from "@mui/material"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import PauseIcon from "@mui/icons-material/Pause"
import StopIcon from "@mui/icons-material/Stop"

import { useDemoScriptContext } from "@context"
import { EMessageType, IObserver, ITick } from "@types"

enum EPlaybackStatus {
  PLAY = "PLAY",
  PAUSE = "PAUSE",
  STOP = "STOP",
}

export const ControlButtons: React.FC = () => {
  const { demoScript } = useDemoScriptContext()

  const [playbackStatus, setPlaybackStatus] = useState<EPlaybackStatus>(EPlaybackStatus.STOP)
  const [isAtEnd, setIsAtEnd] = useState(false)

  const isPlaying = playbackStatus === EPlaybackStatus.PLAY
  const isPause = playbackStatus === EPlaybackStatus.PAUSE
  const isStop = playbackStatus === EPlaybackStatus.STOP

  const { onStart, onPause, onStop } = useMemo(
    () => ({
      onStart: (): void => {
        demoScript.start()
        setPlaybackStatus(EPlaybackStatus.PLAY)
      },
      onPause: (): void => {
        demoScript.pause()
        setPlaybackStatus(EPlaybackStatus.PAUSE)
      },
      onStop: (): void => {
        demoScript.stop()
        setPlaybackStatus(EPlaybackStatus.STOP)
      },
    }),
    []
  )

  const observers = useMemo(
    () => [
      {
        type: EMessageType.STOP,
        cb: (): void => setPlaybackStatus(EPlaybackStatus.STOP),
      },
      {
        type: EMessageType.TICK,
        cb: ({ progress }: ITick): void => {
          if (progress >= 100) setIsAtEnd(true)
          if (progress === 0) setIsAtEnd(false)
        },
      },
    ],
    []
  )

  useEffect(() => {
    demoScript.on(observers as IObserver<EMessageType>[])
  }, [])

  return (
    <Stack direction="row" gap="6px" alignItems="center">
      <SIconButton onClick={onStart} disabled={isPlaying}>
        <PlayArrowIcon
          sx={{
            height: "16px",
            width: "16px",
          }}
        />
      </SIconButton>
      <SIconButton onClick={onPause} disabled={isPause || isStop || isAtEnd}>
        <PauseIcon
          sx={{
            height: "16px",
            width: "16px",
          }}
        />
      </SIconButton>
      <SIconButton onClick={onStop} disabled={isStop}>
        <StopIcon
          sx={{
            height: "16px",
            width: "16px",
          }}
        />
      </SIconButton>
    </Stack>
  )
}

const SIconButton = styled(IconButton)(({ theme }) => ({
  "height": "24px",
  "width": "24px",
  "borderRadius": "2px",
  "background": theme.palette.secondary.A600,

  "&.Mui-disabled": {
    backgroundColor: theme.palette.secondary.A600,
  },
}))
