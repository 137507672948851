import React, { useEffect, useMemo, useState } from "react"
import { LinearProgress, Stack, Typography } from "@mui/material"

import { useDemoScriptContext } from "@context"
import { EMessageType, IObserver, ITick } from "@types"

export const ProgressBar: React.FC = () => {
  const { demoScript } = useDemoScriptContext()

  const [progress, setProgress] = useState(0)
  const [tick, setTick] = useState(0)

  const observer = useMemo(
    () => ({
      type: EMessageType.TICK,
      cb: ({ tick, progress }: ITick): void => {
        setProgress(progress)
        setTick(tick)
      },
    }),
    []
  )

  useEffect(() => {
    demoScript.on(observer as IObserver<EMessageType>)

    return () => demoScript.off(observer as IObserver<EMessageType>)
  }, [])

  return (
    <Stack direction="row" gap="12px" alignItems="center" width="100%">
      <LinearProgress
        value={progress}
        variant="determinate"
        sx={{
          width: "100%",
          height: "10px",
          borderRadius: "3px",
        }}
      />
      <DemoTime tick={tick} />
    </Stack>
  )
}

const DemoTime: React.FC<{ tick: number }> = ({ tick }) => {
  const currentTime = useMemo(() => {
    const seconds = tick % 60
    const minutes = (tick - seconds) / 60

    return `0${minutes}:${seconds > 9 ? seconds : "0" + seconds}`
  }, [tick])

  return <Typography variant="caption" width="100px">{`${currentTime} / 05:00`}</Typography>
}
