import React, { useMemo, useState } from "react"
import { Button, Stack, styled } from "@mui/material"

import { useDemoScriptContext } from "@context"
import { EDemoSpeed } from "@types"

const buttonData = [
  {
    value: EDemoSpeed.X1,
    name: "x1",
  },
  {
    value: EDemoSpeed.X2,
    name: "x2",
  },
  {
    value: EDemoSpeed.X4,
    name: "x4",
  },
  //   {
  //     value: EDemoSpeed.X10,
  //     name: "x10",
  //   },
]

export const SpeedSelect: React.FC = () => {
  const { demoScript } = useDemoScriptContext()

  const [speed, setSpeed] = useState<EDemoSpeed>(EDemoSpeed.X1)

  const buttons = useMemo(
    () =>
      buttonData.map(({ value, name }) => (
        <SButton
          key={value}
          children={name}
          onClick={(e): void => {
            setSpeed(value)
            demoScript.setSpeed(value)
          }}
          className={speed === value ? "active" : undefined}
        />
      )),
    [speed]
  )

  return (
    <Stack direction="row" alignItems="center" gap="4px">
      {buttons}
    </Stack>
  )
}

const SButton = styled(Button)(({ theme }) => ({
  "width": "24px",
  "minWidth": "auto",
  "height": "24px",
  "fontSize": "13px",
  "textTransform": "lowercase",

  "&.active": {
    color: theme.palette.primary.main,
  },
}))
