import React, { useCallback, useMemo, useState } from "react"
import { Select, styled, MenuItem, Typography, SelectChangeEvent } from "@mui/material"

import { useDemoScriptContext, useLocalizationContext } from "@context"
import { ELang } from "@types"

const getDemos = (lang: ELang): string[] => (lang === ELang.RU ? ["Запись 1", "Запись 2"] : ["Record 1", "Record 2"])

export const DemoSelect: React.FC = () => {
  const {
    l: { language },
  } = useLocalizationContext()
  const demos = useMemo(() => getDemos(language), [language])

  const { handleDemoSelect } = useDemoScriptContext()

  const [demo, setDemo] = useState(0)

  const handleDemoChange = useCallback((e: SelectChangeEvent<unknown>) => {
    const newValue = e.target.value as number

    setDemo(newValue)
    handleDemoSelect(newValue)
  }, [])

  const demoMenuItems = useMemo(
    () =>
      demos.map((value, index): React.ReactNode => {
        return (
          <SMenuItem key={index} value={index}>
            <Typography variant="caption">{value}</Typography>
          </SMenuItem>
        )
      }),
    []
  )

  return (
    <SSelect
      value={demo}
      onChange={handleDemoChange}
      children={demoMenuItems}
      sx={{ display: "flex", flexDirection: "row" }}
    />
  )
}

const SSelect = styled(Select)({
  height: "24px",
  width: "200px",
})

const SMenuItem = styled(MenuItem)({
  height: "32px",
})
